/* src/components/Home.css */
/* Reset default styles */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  html, body {
    height: 100%;
    width: 100%;
    overflow-x: hidden;
  }
  
  body {
    background-color: #1a202c; /* Tailwind's gray-900 */
  }

.home {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  background-color: #1a202c;
  padding: 1rem;
  gap: 1.5rem;
  position: relative;
  overflow: hidden;
}

.content {
  position: relative;
  z-index: 1;
  padding: 1rem;
  max-width: 100%;
}

@media (min-width: 768px) {
  .home {
    padding: 4rem;
    gap: 2.5rem;
  }
  
  .content {
    padding: 2rem;
  }
}

.home h1 {
  font-weight: 900;
  color: white;
  margin-bottom: 1rem;
  line-height: 1.2;
  word-wrap: break-word;
}

.home h1 span {
  color: #5303B8;
}

.home h2 {
  font-weight: 500;
  color: white;
  word-wrap: break-word;
}
  
  .home p {
    color: white;
    max-width: 48rem;
    font-size: 1.125rem;
  }
  
  